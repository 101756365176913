<template>
	<div>
		<h1>
			404
			<template v-if="resource">
				{{ resource }}
			</template>
			Not Found
		</h1>
	</div>
</template>

<script>
export default {
	page: {
		title: '404',
		meta: [{ name: 'description', content: '404' }]
	},
	props: {
		resource: {
			type: String,
			default: ''
		}
	}
}
</script>

<style lang="scss" module>
.title {
  text-align: center;
}
</style>
